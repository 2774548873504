//teste[Math.floor(Math.random()* teste.length)] pegue

import { useEffect, useState } from "react";

import Cartao from "../Card/Card";

//valores aleatorios dentro do array
const Estudantes = ({ bruxos }) => {
  const [newbruxos, setBruxos] = useState([]);

  const sortearEstudante = (array) => {
    let alunos = [];
    let casas = [];
    while (alunos.length < 3) {
      let alunoAleatorio = array[Math.floor(Math.random() * array.length)];
      console.log(alunoAleatorio);
      let casa = alunoAleatorio.house;
      if (!casas.includes(casa)) {
        casas.push(casa);
        alunos.push(alunoAleatorio);
        // setCasas([...casas, casa]);
        // setAlunos([...alunos, alunoAleatorio]);
      }
    }

    return alunos;
  };

  useEffect(() => {
    if (bruxos.length !== 0 && newbruxos.length < 3) {
      setBruxos(sortearEstudante(bruxos));
    }
    //eslint-disable-next-line
  }, [bruxos]);

  return (
    <>
      <Cartao alunosDistintos={newbruxos} />
    </>
  );
};

export default Estudantes;
