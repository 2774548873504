import { useEffect, useState } from "react";

import "./App.css";

import Estudantes from "./components/Student/Students";

function App() {
  //State para armazenar o valor da requisição
  const [bruxo, Setbruxo] = useState([]);

  //requisição API
  useEffect(() => {
    fetch("https://hp-api.herokuapp.com/api/characters/students")
      .then((response) => response.json())
      .then((response) => Setbruxo(response))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Estudantes bruxos={bruxo} />
      </header>
    </div>
  );
}

export default App;
