import "./styles.css";

import huff from "../../assets/images/huff.png";

import sonserina from "../../assets/images/sonserina.png";

import grifinoria from "../../assets/images/grifinoria.png";

import raven from "../../assets/images/raven.png";

const Cartao = ({ alunosDistintos }) => {
  return (
    <>
      <div className="selected">Bruxos selecionados</div>
      <main>
        {alunosDistintos.map((el, index) => {
          return (
            <div key={index} className={el.house}>
              <div className="text1">Representando {el.house}:</div>
              <div className="text2">{el.name}</div>
              <img src={el.image} alt="" className="img" />
              <img
                src={
                  el.house === "Hufflepuff"
                    ? huff
                    : el.house === "Slytherin"
                    ? sonserina
                    : el.house === "Gryffindor"
                    ? grifinoria
                    : raven
                }
                alt=""
                className="houses"
              />
            </div>
          );
        })}
      </main>
    </>
  );
};
/*
Gryffindor
Hufflepuff
Slytherin
Ravenclaw

*/
export default Cartao;
